.md-ranking {
  background: #000;
  color: #fff;

  .container {
    .search-field {
      margin-top: 21px;
    }
    .filter-panel {
      width: 25%;
    }
    .main-panel {
      width: 65%;
    }

    .search-field, .filter-field {
      .search-input-field {
        position: relative;
        display: block;
        margin-right: 28px;
        width: 50%;
        min-width: 250px;
        height: 45px;

        .search-input {
          width: 100%;
          height: 100%;
          background: #C8C8C8;
          border-radius: 0;
          border: 0;
          outline: 0;
        }

        .search-icon {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          width: 27px;
        }
      }

      .btn-search, .btn-filter {
        width: 109px;
        height: 45px;
        background: #85FF10;
        border: 0;
        outline: 0;
        border-radius: 0;
      }

      .btn-filter {
        margin-top: 100px;
      }
    }

    .filter-items-panel {
      .filter-items {
        margin-top: 44px;

        .filter-item-title {
          text-transform: capitalize;
          font-size: 25px;
          margin-bottom: 26px;
        }

        .filter-item-panel {
          .filter-item-cover {
            padding-left: 5px;
            padding-bottom: 5px;

            .filter-item {
              width: 100%;
              padding: 0 10px;
              background: transparent;
              color: #fff;
              border: 1px solid #fff;
              font-size: 18px;

              &.active {
                color: #85FF10;
                border: 1px solid #85FF10;
              }
            }
          }
        }
      }
    }
    .product-panel {
      margin-top: 100px;
      .product-item {
        width: 30%;
        margin-bottom: 73px;
        cursor: pointer;

        img {
          width: 100%;
          border-radius: 25px;
          margin-bottom: 32px;
        }

        .product-text {
          font-size: 25px;
        }
      }
    }
  }
}