.btn-cancel {
  padding: 30px;
}
.modal-mddog-detail {
  .modal-content {
    background-color: #d9d9d9;
    .modal-body {
      height: 100%;
      padding-left: 50px;
      padding-right: 50px;
      //   background-color: #d9d9d9;
      img {
        border-radius: 25px;
      }
      .btn-cancel {
        padding: 10px;
      }
      .btn-download {
        margin-top: 15px;
        margin-bottom: 30px;
        padding: 5px;
        border-radius: 5px;
        background-color: #85ff10;
        font-family: "Barlow Condensed";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;

        color: #000000;
        border: none;
      }
      .maddog-name {
        font-family: "Barlow Condensed";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 54px;
        text-align: center;
        color: black;
        margin-bottom: 20px;
      }
      .token-table {
        text-align: left;
        border: #000000;
        font-family: "Barlow Condensed";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        justify-content: center;
        display: flex;
      }
    }
  }
}
