.md-token {
  background-color: #000;
  color: #fff;

  .about-section {
    background-image: url(../assets/images/md_token.png);
    background-size: cover;
    background-position: bottom left;
    color: #fff;
    .container {
      padding-top: 161px;
      padding-bottom: 193px;

      .md-racing-logo {
        width: 100%;

        img {
          width: 100%;
          max-width: 1070px;
        }
      }

      .btn-panel {
        margin-top: 30px;

        .btn {
          min-width: 185px;
          height: 58px;
          border-radius: 0;
          border: 0;
          outline: 0;
          box-shadow: none;
          color: #000;
          background-color: #85ff10;
        }
        p {
          margin-top: 19px;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
        }

        @media (max-width: 768px) {
          margin-top: 20px;
          .btn {
            min-width: 131px;
            height: 53px;
          }
          p {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }

      @media (max-width: 768px) {
        padding-top: 46px;
        padding-bottom: 77px;
      }
    }
  }

  .sales-section {
    padding-top: 146px;
    padding-bottom: 112px;

    .container {
      &.text-panel {
        max-width: 725px;
        padding-bottom: 100px;
      }
    }

    .video-panel {
      width: 100%;
      padding-top: 47%;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 768px) {
      padding-top: 58px;
      padding-bottom: 45px;
      .container {
        &.text-panel {
          padding: 0 73px 45px 73px;
        }
      }
    }
  }

  .platform-section {
    background-color: #000;
    color: #fff;

    .container {
      .font-h1 {
        margin-bottom: 112px;
      }

      .text-panel {
        .text-item {
          width: 33.3px;
          padding: 37px 37px 70px 37px;

          .font-h4 {
            margin-bottom: 20px;
          }

          &:not(:last-child) {
            border-right: 1px solid #fff;
          }
        }
      }

      .platform-img {
        margin-top: -142px;
        width: 100%;
      }

      @media (max-width: 768px) {
        padding: 0 32px;
        .font-h1 {
          margin-bottom: 29px;
        }
        .text-panel {
          flex-direction: column;

          .text-item {
            width: 100%;
            padding: 27px 32px 28px 32px;

            &:not(:last-child) {
              border-right: 0;
              border-bottom: 1px solid #fff;
            }
          }
        }
        .platform-img {
          margin-top: 0;
        }
      }
    }
  }

  .utility-section {
    padding-top: 16px;
    background-color: #000;
    color: #fff;

    .font-h1 {
      margin-bottom: 49px;
    }

    .font-h5 {
      margin: auto;
      max-width: 1034px;
      margin-bottom: 94px;
    }

    .text-panel {
      .text-item {
        width: 33.3px;
        padding: 37px 37px 70px 37px;

        .font-h4 {
          margin-bottom: 20px;
        }

        .font-h5 {
          margin: 0;
        }

        &:not(:last-child) {
          border-right: 1px solid #fff;
        }
      }
    }

    .images-panel {
      padding: 0 70px;
      margin-top: -157px;

      .garage-img {
        width: 100%;
        max-width: 874px;
      }
    }

    @media (max-width: 768px) {
      padding-top: 51px;
      .font-h1 {
        margin-bottom: 41px;
      }
      .font-h5 {
        padding: 0 42px;
        margin-bottom: 53px;
      }
      .text-panel {
        flex-direction: column;
        margin-bottom: 50px;

        .text-item {
          width: 100%;
          padding: 27px 32px 28px 32px;

          .font-h5 {
            padding: 0;
          }

          &:not(:last-child) {
            border-right: 0;
            border-bottom: 1px solid #fff;
          }
        }
      }
      .images-panel {
        margin-top: -70px;
      }
    }
    @media (max-width: 375px) {
      .font-h5 {
        padding: 0px;
      }
    }
  }

  .partnership-section {
    background-color: #000;
    color: #fff;
    padding-bottom: 139px;

    .container {
      max-width: 1058px;

      .text-panel {
        .font-h1 {
          margin-bottom: 49px;
        }

        .font-h5 {
          margin-bottom: 89px;
        }
      }
      .img-group {
        display: flex;
        flex-direction: column;
        gap: 73px;
        .img-panel {
          gap: 126px;
          img {
            width: 263px;
            border-radius: 50%;
          }
        }
      }
    }
    @media (max-width: 1100px) {
      padding-bottom: 60px;
      .container {
        padding: 0 43px;

        .text-panel {
          margin-bottom: 60px;

          .font-h1 {
            margin-bottom: 32px;
          }
        }
        .img-group {
          .img-panel {
            gap: 70px;
            img {
              width: 200px;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      padding-bottom: 60px;
      .container {
        padding: 0 43px;

        .text-panel {
          margin-bottom: 60px;

          .font-h1 {
            margin-bottom: 32px;
          }
        }
        .img-group {
          gap: 73px;
          .img-panel {
            gap: 73px;
            flex-direction: column;
            align-items: center;
            justify-content: start;

            img {
              width: 263px;

              // &:not(:last-child) {
              //   margin-bottom: 42px;
              // }
            }
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .container {
      padding-left: 12px !important;
      padding-right: 12px !important;

      .text-panel {
        .text-item {
          padding-left: 12px !important;
          padding-right: 12px !important;
        }
      }
    }
  }
}
