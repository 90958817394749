.mad-dogs-page,
.mad-dog-cars-page,
.mad-racers,
.md-license,
.md-racing {
  .container {
    max-width: 1448px;
  }

  .about-section {
    background-image: url(../assets/images/about_bg.png);
    background-size: cover;
    background-position: bottom left;
    color: #fff;

    .centario-avatar {
      width: 50%;

      img {
        width: 100%;
      }
    }

    .text-panel {
      flex: 1;
      padding: 40px 0;
      line-height: 1.2;

      .font-title {
        margin-bottom: 36px;
      }

      .font-h4 {
        margin-bottom: 36px;
      }

      .btn-panel {
        margin-top: 30px;

        .btn {
          width: 185px;
          height: 58px;
          border-radius: 0;
          border: 0;
          outline: 0;
          box-shadow: none;
          color: #000;
          background-color: #85ff10;

          &:not(:last-child) {
            margin-right: 40px;
          }
        }

        @media (max-width: 768px) {
          margin-top: 20px;
          flex-direction: column;
          align-items: start !important;
          .btn {
            width: 131px;
            height: 49px;

            &:not(:last-child) {
              margin-right: 0px;
              margin-bottom: 17px;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      .container {
        padding: 0 48px;
        flex-direction: column-reverse;

        .centario-avatar {
          width: 100%;
        }

        .text-panel {
          padding-top: 51px;
          padding-bottom: 0;
          margin-bottom: -100px;
        }
      }
    }
    @media (max-width: 550px) {
      .container {
        .text-panel {
          margin-bottom: -50px;
        }
      }
    }
    @media (max-width: 320px) {
      .container {
        padding: 0 28px;
      }
    }
  }

  .sales-section {
    background-color: #000;
    color: #fff;
    padding-top: 113px;
    padding-bottom: 137px;

    .font-h1 {
      margin-bottom: 40px;
    }

    .sale-text-panel {
      margin-bottom: 53px;

      .sale-text-item {
        &:not(:first-child) {
          padding-left: 25px;
        }

        &:not(:last-child) {
          padding-right: 25px;
          border-right: 1px solid #fff;
        }

        .sale-text_value {
          font-size: 36px;
        }

        .sale-text_label {
          font-size: 18px;
          margin-bottom: 5px;
        }
      }
    }

    .visit-btn {
      width: 180px;
      height: 58px;
      background-color: #85ff10;
      margin-bottom: 48px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    .slick-container {
      max-width: 1390px;
      height: 470px;
      padding: 0 30px;

      .slick-item {
        padding: 32px 55px;
        transition-duration: 0.5s;

        .img-panel {
          position: relative;
          width: 100%;
          padding-top: 97%;
          margin-bottom: 33px;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        img {
          width: 100%;
          // min-height: 300px;
          object-fit: cover;
          margin-bottom: 33px;
          border-radius: 25px;
        }

        .slick-text {
          font-size: 16px;
          text-transform: uppercase;
        }
      }

      .slick-center {
        .slick-item {
          transition-duration: 0.5s;
          padding: 0px 20px;
        }
      }

      .slick-arrow {
        &::before {
          width: 18px;
          height: 31px;
          display: block;
          background-size: 100% 100%;
        }

        &.slick-prev {
          &::before {
            content: "";
            background-image: url(../assets/images/arrow_left.svg);
          }
        }

        &.slick-next {
          &::before {
            content: "";
            background-image: url(../assets/images/arrow_right.svg);
          }
        }
      }

      @media (max-width: 768px) {
        padding: 0;
        height: auto;
        .slick-item {
          padding: 0 17px;
        }
        .slick-center {
          .slick-item {
            padding: 0 17px;
          }
        }
        .slick-arrow {
          display: none !important;
        }
      }
    }

    .utility-panel {
      padding-top: 120px;

      .utility-text-panel,
      .perks-text-panel {
        padding-left: 120px;
        padding-top: 20px;

        .font-title {
          margin-bottom: 28px;
        }

        @media (max-width: 991px) {
          padding-left: 70px;
        }
      }

      .utility-item-panel {
        .utility-item {
          width: 100%;
          min-height: 85px;
          background-color: #85ff10;
          font-size: 32px;
          padding: 20px 35px;
          margin-right: -12px;
          color: #000;

          &:not(:last-child) {
            margin-bottom: 60px;
          }

          &:nth-child(2) {
            width: 90%;
          }

          &:nth-child(3) {
            width: 80%;
          }
        }
      }

      @media (max-width: 768px) {
        padding-top: 60px;
        .container {
          flex-direction: column;
          padding-left: 40px;

          .utility-text-panel,
          .perks-text-panel {
            width: 100% !important;
            padding-left: 0;
            margin-bottom: 80px;
          }

          .utility-item-panel {
            width: 100% !important;
          }
        }
      }
      @media (max-width: 550px) {
        padding-top: 30px;
        .container {
          .utility-text-panel {
            margin-bottom: 40px;
          }

          .utility-item-panel {
            .utility-item {
              display: flex;
              align-items: center;
              min-height: 66px;
              padding: 16px 19px;
              font-size: 24px;

              &:not(:last-child) {
                margin-bottom: 30px;
              }
            }
          }
        }
      }
      @media (max-width: 320px) {
        .container {
          .utility-item-panel {
            .utility-item {
              padding: 10px 12px;
            }
          }
        }
      }
    }

    @media (max-width: 550px) {
      padding-top: 29px;
      padding-bottom: 61px;
      .font-h1 {
        font-size: 36px;
      }
      .sale-text-panel {
        margin-bottom: 28px;

        .sale-text-item {
          .sale-text_value {
            font-size: 24px;
          }

          .sale-text_label {
            font-size: 14px;
          }
        }
      }
      .visit-btn {
        width: 131px;
        height: 39px;
      }
    }
    @media (max-width: 320px) {
      .sale-text-panel {
        .sale-text-item {
          &:not(:first-child) {
            padding-left: 15px;
          }

          &:not(:last-child) {
            padding-right: 15px;
          }
        }
      }
      .utility-panel {
        .container {
          padding-left: 20px;
        }
      }
    }
  }

  .road-map-section {
    background-color: #000;

    img {
      width: 100%;
    }

    @media (max-width: 768px) {
      .container {
        padding: 0;
      }
    }
  }

  .faq-section {
    background-color: #000;
    color: #fff;

    .container {
      max-width: 1200px;
      padding: 95px 30px 0 30px;

      .faq-text-panel {
        .faq-item {
          padding: 60px 35px 30px 35px;
          border-bottom: 1px solid #fff;

          .font-button1 {
            margin-bottom: 13px;
            text-transform: uppercase;
          }
        }
      }

      @media (max-width: 550px) {
        padding-top: 50px;
        .font-h1 {
          font-size: 36px;
        }
        .faq-text-panel {
          .faq-item {
            padding: 27px 40px;
            margin-top: 16px;

            .font-nav {
              font-size: 14px;
            }
          }
        }
      }
      @media (max-width: 320px) {
        .faq-text-panel {
          .faq-item {
            padding: 27px 10px;
          }
        }
      }
    }
  }
}

.mad-dogs-page {
  .about-section {
    .container {
      @media (max-width: 768px) {
        overflow: hidden;
        padding: 0 25px;
        .text-panel {
          padding-left: 23px;
          padding-right: 23px;
        }
        .centario-avatar {
          position: relative;
          padding-top: 100%;
          margin-top: -150px;

          img {
            position: absolute;
            top: 0;
            right: -80px;
          }
        }
      }
      @media (max-width: 360px) {
        padding: 0 15px;
        .centario-avatar {
          margin-top: 0px;

          img {
            right: -30px;
          }
        }
      }
    }
  }
}

.mad-racers {
  .sales-section {
    .font-h1 {
      margin-bottom: 40px;
    }
  }

  .about-section {
    .centario-avatar {
      position: relative;

      img {
        position: relative;
        z-index: 1;
      }

      .countdown-panel {
        position: absolute;
        top: 77px;
        width: 242px;
        height: 207px;
        background: #000000;
        border-radius: 10px;
        padding: 15px 30px;

        .countdown-title {
          font-size: 21px;
        }

        .countdown-items {
          .countdown-item {
            background: #85ff10;
            border-radius: 10px;
            width: 71px;
            height: 105px;
            font-size: 96px;
            color: #000;

            &:not(:last-child) {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .about-section {
      .container {
        padding-left: 25px;
        padding-right: 25px;
        .text-panel {
          margin-top: 50px;
          padding: 0 23px;
          margin-bottom: 0px;
        }
        .centario-avatar {
          margin-top: 250px;
          .countdown-panel {
            width: 220px;
            height: 190px;
            top: -100px;
            left: 50%;
            transform: translate(-50%, -50%);

            .countdown-title {
              font-size: 18px;
            }

            .countdown-items {
              .countdown-item {
                width: 60px;
                height: 90px;
                font-size: 70px;
              }
            }
          }
        }
      }
    }
    .sales-section {
      padding-top: 50px;

      .font-h1 {
        margin-bottom: 80px;
      }
    }
  }
  @media (max-width: 550px) {
    .about-section {
      .container {
        padding-left: 12px;
        padding-right: 12px;
        .text-panel {
          padding: 0 13px;
        }
      }
    }
    .sales-section {
      padding-top: 20px;

      .font-h1 {
        margin-bottom: 56px;
      }
    }
  }
}

.mad-dog-cars-page {
  .about-section {
    .container {
      max-height: 643px;
      overflow: hidden;

      .images-panel {
        width: 50%;

        img {
          margin-bottom: 20px;
          width: 100%;
        }

        .left-images-panel {
          margin-right: 20px;
          padding-top: 28px;
          width: 37%;
        }

        .right-images-panel {
          margin-top: -80px;
          width: 37%;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        max-height: 100%;
        .images-panel {
          width: auto;
          justify-content: center;
          margin-top: -40%;

          img {
            margin-bottom: 12px;
          }

          .left-images-panel {
            min-width: 130px;
            margin-right: 12px;
            padding-top: 0px;
            padding-bottom: 40px;
            justify-content: end;
          }

          .right-images-panel {
            min-width: 130px;
            margin-top: 0px;
          }
        }
        .text-panel {
          margin-bottom: 145px;
        }
      }
    }
  }
}

.md-license {
  .about-section {
    .container {
      padding-top: 110px;
      padding-bottom: 110px;

      .md-license-avatar {
        width: 50%;

        img {
          width: 100%;
          max-width: 572px;
        }
      }

      @media (max-width: 768px) {
        padding-top: 55px;
        padding-bottom: 102px;
        .text-panel {
          padding-top: 0;
          margin-bottom: 0;
        }
        .md-license-avatar {
          width: 100%;
        }
      }
    }
  }

  .sales-section {
    .utility-panel {
      .container {
        .perks-text-panel {
          border-left: 1px solid #fff;
        }

        .utility-text-panel {
          padding-right: 20px;
        }
      }

      @media (max-width: 768px) {
        .container {
          padding: 0 36px;

          .perks-text-panel {
            border-left: 0;
            border-top: 1px solid #fff;
            padding-top: 40px;
          }

          .utility-text-panel {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

.md-racing {
  background-color: #000;
  color: #fff;

  .about-section {
    .container {
      padding-top: 161px;
      padding-bottom: 193px;

      .md-racing-logo {
        width: 100%;

        img {
          width: 100%;
          max-width: 1070px;
        }
      }

      .btn-panel {
        margin-top: 30px;

        .btn {
          min-width: 185px;
          height: 58px;
          border-radius: 0;
          border: 0;
          outline: 0;
          box-shadow: none;
          color: #000;
          background-color: #85ff10;
        }
        p {
          margin-top: 19px;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
        }

        @media (max-width: 768px) {
          margin-top: 20px;
          .btn {
            min-width: 131px;
            height: 53px;
          }
          p {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }

      @media (max-width: 768px) {
        padding-top: 46px;
        padding-bottom: 77px;
      }
    }
  }

  .sales-section {
    padding-bottom: 112px;

    .container {
      &.text-panel {
        max-width: 1087px;
        padding-bottom: 100px;
      }
    }

    .video-panel {
      width: 100%;
      padding-top: 47%;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 768px) {
      padding-top: 58px;
      padding-bottom: 45px;
      .container {
        &.text-panel {
          padding: 0 73px 45px 73px;
        }
      }
    }
  }

  .game-modes-section {
    background-color: #000;
    color: #fff;

    .container {
      .font-h1 {
        margin-bottom: 112px;
      }

      .text-panel {
        .text-item {
          width: 33.3px;
          padding: 37px 37px 70px 37px;

          .font-h4 {
            margin-bottom: 20px;
          }

          &:not(:last-child) {
            border-right: 1px solid #fff;
          }
        }
      }

      .game-mode-img {
        margin-top: -142px;
        width: 100%;
      }

      @media (max-width: 768px) {
        padding: 0 32px;
        .font-h1 {
          margin-bottom: 29px;
        }
        .text-panel {
          flex-direction: column;

          .text-item {
            width: 100%;
            padding: 27px 32px 28px 32px;

            &:not(:last-child) {
              border-right: 0;
              border-bottom: 1px solid #fff;
            }
          }
        }
        .game-mode-img {
          margin-top: 0;
        }
      }
    }
  }

  .garage-section {
    padding-top: 16px;
    background-color: #000;
    color: #fff;

    .font-h1 {
      margin-bottom: 49px;
    }

    .font-h5 {
      margin: auto;
      max-width: 1034px;
      margin-bottom: 94px;
    }

    .text-panel {
      .text-item {
        width: 33.3px;
        padding: 37px 37px 70px 37px;

        .font-h4 {
          margin-bottom: 20px;
        }

        .font-h5 {
          margin: 0;
        }

        &:not(:last-child) {
          border-right: 1px solid #fff;
        }
      }
    }

    .images-panel {
      padding: 0 70px;
      margin-top: -157px;

      .garage-img {
        width: 100%;
        max-width: 874px;
      }
    }

    @media (max-width: 768px) {
      padding-top: 51px;
      .font-h1 {
        margin-bottom: 41px;
      }
      .font-h5 {
        padding: 0 42px;
        margin-bottom: 53px;
      }
      .text-panel {
        flex-direction: column;
        margin-bottom: 50px;

        .text-item {
          width: 100%;
          padding: 27px 32px 28px 32px;

          .font-h5 {
            padding: 0;
          }

          &:not(:last-child) {
            border-right: 0;
            border-bottom: 1px solid #fff;
          }
        }
      }
      .images-panel {
        margin-top: -70px;
      }
    }
    @media (max-width: 375px) {
      .font-h5 {
        padding: 0px;
      }
    }
  }

  .racer-profile-section {
    background-color: #000;
    color: #fff;
    padding-bottom: 139px;

    .container {
      max-width: 1058px;

      .text-panel {
        .font-h1 {
          margin-bottom: 49px;
        }

        .font-h5 {
          margin-bottom: 89px;
        }
      }

      .img-panel {
        img {
          width: 263px;
          border-radius: 50%;
        }
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 60px;
      .container {
        padding: 0 43px;

        .text-panel {
          margin-bottom: 60px;

          .font-h1 {
            margin-bottom: 32px;
          }
        }

        .img-panel {
          flex-direction: column;
          align-items: center;
          justify-content: start;

          img {
            width: 165px;

            &:not(:last-child) {
              margin-bottom: 42px;
            }
          }
        }
      }
    }
  }

  .achievement-section {
    background-color: #000;
    color: #fff;
    padding-bottom: 135px;

    .container {
      max-width: 1058px;

      .text-panel {
        margin-bottom: 41px;

        .font-h1 {
          margin-bottom: 49px;
        }
      }

      .img-panel {
        img {
          &:not(:last-child) {
            margin-right: 27px;
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 64px;
      .container {
        padding: 0 43px;

        .text-panel {
          margin-bottom: 35px;

          .font-h1 {
            margin-bottom: 32px;
          }
        }

        .img-panel {
          img {
            width: 39px;

            &:not(:last-child) {
              margin-right: 14px;
            }
          }
        }
      }
    }
  }

  .upcoming-section {
    padding-bottom: 135px;

    .container {
      max-width: 1058px;

      .text-panel {
        .font-h1 {
          margin-bottom: 49px;
        }

        .text-item {
          margin-bottom: 30px;
        }
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 68px;
      .container {
        padding: 0 30px;

        .text-panel {
          .font-h1 {
            margin-bottom: 47px;
          }
        }
      }
    }
    @media (max-width: 550px) {
      .container {
        .font-nav {
          font-size: 14px;
        }
      }
    }
  }

  .facts-section {
    .container {
      max-width: 1058px;

      .text-panel {
        margin-bottom: 85px;

        .font-h1 {
          margin-bottom: 49px;
        }

        .font-h5 {
          margin-bottom: 30px;
        }
      }

      .btn-preview {
        display: block;
        margin: auto;
        background: #85ff10;
        padding: 17px 5px;
        border-radius: 0;
        border: 0;
        color: #000;
      }
    }

    @media (max-width: 768px) {
      .container {
        padding: 0 37px;

        .text-panel {
          margin-bottom: 33px;

          .font-h1 {
            margin-bottom: 28px;
          }

          .font-h5 {
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 375px) {
    .container {
      padding-left: 12px !important;
      padding-right: 12px !important;

      .text-panel {
        .text-item {
          padding-left: 12px !important;
          padding-right: 12px !important;
        }
      }
    }
  }
}
