.md-leaderboard {
  background-color: black;

  .about-section {
    background-image: url(../assets/images/about_bg.png);
    background-size: cover;
    background-position: bottom left;
    color: #fff;
    .leaderboard-title {
      text-align: left;
      padding: 50px;
      font-family: "Barlow Condensed";
      font-style: normal;
      font-weight: 700;
      font-size: 70px;
      line-height: 84px;
      color: #ffffff;
      @media (max-width: 990px) {
        font-size: 50px;
        line-height: 54px;
        padding: 50px;
      }
      @media (max-width: 550px) {
        font-size: 36px;
        line-height: 43px;
        padding-top: 50px;
        padding-left: 50px;
        padding-bottom: 10px;
      }
    }

    .img-panel {
      box-sizing: border-box;
      padding: 20px;
      .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
      }

      .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      img {
        height: 129px;
        width: 129px;
        margin: 24px;
        @media (max-width: 550px) {
          height: 50px;
          width: 50px;
          margin: 10px;
        }
      }
    }

    @media (max-width: 768px) {
      .container {
        padding: 0 48px;
        flex-direction: column-reverse;

        .centario-avatar {
          width: 100%;
        }

        .text-panel {
          padding-top: 51px;
          padding-bottom: 0;
          margin-bottom: -100px;
        }
      }
    }
    @media (max-width: 550px) {
      .container {
        .text-panel {
          margin-bottom: -50px;
        }
      }
    }
    @media (max-width: 320px) {
      .container {
        padding: 0 28px;
      }
    }
  }
  .leaderboard-section {
    padding: 0 113px;
    color: white;
    @media (max-width: 820px) {
      padding: 0 40px;
    }
    @media (max-width: 550px) {
      padding: 0 25px;
    }
    .text-panel {
      text-align: center;
      flex: 1;
      // padding: 40px 50px;
      line-height: 1.2;
      .font-title {
        padding-top: 70px;
        padding-bottom: 10px;
        @media (max-width: 550px) {
          padding: 44px 0;
        }
      }
      .font-subtitle {
        font-family: "Barlow Condensed";
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 62px;

        @media (max-width: 550px) {
          gap: 20px;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
    .prize-panel {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 30px;
      line-height: 36px;
      font-style: bold;
      color: #85ff10;
      padding-top: 33px;
      max-width: 568px;
      width: 100%;
      border: 1px solid #85ff10;
      padding-bottom: 20px;
      margin-bottom: 90px;


      div {
        font-family: "Barlow Condensed";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        text-decoration-line: underline;

        color: #85ff10;

        padding-bottom: 20px;
      }
      @media (max-width: 688px) {
        padding: 20px 5px;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
      }

      @media (max-width: 550px) {
        padding: 20px 5px;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 20px;
      }
    }
    hr {
      border: 2px solid #ffffff;
      @media (max-width: 450px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 990px) {
        width: 100%;
      }
    }
    .leaderboard-item {
      padding: 44px 70px;
      max-width: 1108px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: start;
      .number {
        text-align: center;

        background-color: white;
        font-family: "Barlow";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
        width: 71px;
        height: 71px;
        border: none;
        &.active {
          background-color: #85ff10;
        }
      }
      .icon {
        margin-left: 120px;
        margin-right: 100px;
        width: 404px;
        display: flex;
        align-items: center;
        gap: 25px;

        img {
          width: 129px;
          height: 129px;
          border-radius: 25px;
          &.special {
            width: 300px;
            height: auto;
          }
        }

        p {
          font-family: "Barlow";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;
        }
      }
      .referal {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        p {
          font-family: "Barlow";
          font-style: normal;
          font-weight: 700;
          font-size: 45px;
          line-height: 54px;
          /* identical to box height */

          text-align: center;

          color: #85ff10;
        }
      }

      @media (max-width: 990px) {
        padding: 12px 15px;
        width: 100%;
        .number {
          font-size: 36px;
          line-height: 38px;
          width: 50px;
          height: 50px;
        }
        .icon {
          margin-left: 10px;
          margin-right: 10px;
          img {
            width: 49px;
            height: 49px;
            &.special {
              width: 200px;
              height: auto;
            }
          }
          p {
            font-size: 20px;
            line-height: 22px;
          }
        }
        .referal {
          font-size: 16px;
          line-height: 18px;
          p {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
      @media (max-width: 768px) {
        padding: 12px 15px;
        width: 100%;
        .number {
          font-size: 24px;
          line-height: 26px;
          width: 35px;
          height: 35px;
        }
        .icon {
          margin-left: 10px;
          margin-right: 10px;

          img {
            width: 45px;
            height: 45px;
            &.special {
              width: 150px;
              height: auto;
            }
          }
          p {
            font-size: 14px;
            line-height: 16px;
          }
        }
        .referal {
          font-size: 10px;
          line-height: 12px;
          p {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }

      @media (max-width: 450px) {
        padding: 12px 15px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .number {
          font-family: "Barlow Condensed";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 38px;

          text-align: center;
        }
        .icon {
          margin-left: 10px;
          margin-right: 10px;
          flex-direction: column;
          img {
            width: 112px;
            height: 112px;
            &.special {
              width: 230px;
              height: auto;
            }
          }
          p {
            font-family: "Barlow Condensed";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
          }
        }
        .referal {
          font-family: "Barlow Condensed";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          p {
            font-family: "Barlow Condensed";
            font-style: normal;
            font-weight: 700;
            font-size: 45px;
            line-height: 54px;
            /* identical to box height */

            text-align: center;
          }
        }
      }
    }
  }
}
