.terms-page {
  background-color: #000;
  color: #fff;
  .container {
    max-width: 1169px;
    a {
      text-decoration: underline;
      font-weight: 400;
    }
    h1 {
      margin-bottom: 70px;
      @media (max-width: 550px) {
        margin-bottom: 30px;
        font-size: 32px;
      }
    }
  }
}
