.whitepaper {
  color: white;
  text-align: center;
  .font-70 {
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 84px;
  }
  .font-30 {
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .font-24 {
    text-align: center;
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }
  .bold {
    font-weight: 700;
  }

  .font-18 {
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .container {
    max-width: 996px;
    .content {
      margin-top: 36px;
      display: flex;
      justify-content: center;
      text-align: left;
      .do-list {
        list-style-type: number;
        display: flex;
        flex-direction: column;
      }
    }
    .btn-download {
      margin-top: 60px;
      button {
        border: none;
        padding: 18px 25px;
        background: #85ff10;
        color: black;
        font-family: "Barlow Condensed";
        font-style: normal;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;
        a {
          font-weight: 700;
        }
        a:hover {
          color: black;
        }
      }

      p {
        font-family: "Barlow Condensed";
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;

        color: #ffffff;
      }
      @media (max-width: 768px) {
        button {
          font-size: 18px;
          padding: 10px 15px;
        }
      }
    }
  }
}
