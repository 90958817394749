.partners {
  color: white;
  text-align: center;
  .font-70 {
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 84px;
  }
  .font-30 {
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .font-24 {
    text-align: center;
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
  }
  .font-18 {
    font-family: "Barlow Condensed";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .container {
    max-width: 996px;
    .content {
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        gap: 100px;
        align-items: center;
        justify-content: center;
      .partner-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        .image {
          align-items: center;
          display: flex;
          justify-content:center;
          width: 220px;
          height: 220px;
        }
        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 10px;
        }

      }
    }
  }
}
