.modal-content {
  background-color: black;

  #close-btn {
    padding: 5px;
    color: white;
    width: 30px;
    height: 30px;
    background-color: black;
  }
  .modal-body {
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 78px;
    text-align: center;
    text-transform: uppercase;
    height: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    .mint-btn {
      color: black;
      background: #85ff10;
      font-family: "Barlow Condensed";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      padding: 17px 40px;
      border: none;
    }
  }
  @media (max-width: 550px) {
    .modal-body {
      font-size: 36px;
      line-height: 40px;
      margin-bottom: 15px;
      .mint-btn {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 12px 25px;
        border: none;
      }
    }
  }
}
