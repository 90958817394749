.landing-page {
  background: url("../assets/images/MDCC_LandingPage_Bg.gif");
  background-size: cover;
  color: #fff;

  .bg-cover {
    background-color: rgba(0, 0, 0, 0.72);
  }

  .main-panel {
    min-height: 100vh;
    padding-top: 83px;
    padding-bottom: 34px;
    @media (max-width: 550px) {
      padding-top: 64px;
      padding-bottom: 67px;
      .mdcc-logo {
        width: 179px;
        margin-bottom: 12px;
      }
      h1 {
        margin-bottom: 12px;
      }
    }
  }

  .links-panel {
    margin-top: 44px;
    margin-bottom: 11px;

    .link-item {
      width: 376px;
      height: 109px;
      color: #000;
      background-color: #85ff10;
      margin-bottom: 34px;
    }

    @media (max-width: 1200px) {
      .link-item {
        width: 300px;
        height: 85px;
      }
    }
    @media (max-width: 991px) {
      justify-content: space-around !important;
    }
    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
      justify-content: start;
      margin-bottom: 22px;
      .link-item {
        width: 210px;
        height: 60px;
        margin-bottom: 20px;
      }
    }
  }

  .social-panel {
    a {
      &:not(:last-of-type) {
        margin-right: 20px;
      }

      @media (max-width: 550px) {
        img {
          width: 36px;
        }
      }
    }
  }
}
